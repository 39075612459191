/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  Container,
  HStack,
  Spacer,
} from '@chakra-ui/react'
import React, { useEffect, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import GreneLogo from '../assets/images/grene-logo-text.png'
import VILLogo from '../assets/images/vi_logo_large_white.png'
import { useLoadS3Creds } from '../hooks/shared.hooks'
import { loginConst } from '../utils/action.constant'
import {
  getLocalAuthDetails,
  setLocalAuthDetails,
  getISTtoUTC,
} from '../utils/common.util'
import { useLastSeenUpdate } from '../hooks/login.hooks'
import { useAuthContext } from '../context/auth.context'
import { SharedContextProvider } from '../context/shared.context'
import {
  useLoadAssetInlineCollection,
  useLoadAwsBucketName,
  useLoadAssetAuthToken,
  useAddTimeSheetData,
} from '../hooks/asset.hooks'
import { TIMELINE_MESSAGE_TYPE } from '../components/timeline/util'
import { useLanguageContext } from '../context/locale.context'

const WebLinkLayout = ({ children, orgId, formName }) => {
  const imageData = orgId === 868 ? VILLogo : GreneLogo
  const { dispatch } = useAuthContext()
  const {
    state: { locale },
  } = useLanguageContext()
  const history = useHistory()
  const [user, setUser] = useState(null)

  const { mutate, isLoading } = useLoadAssetInlineCollection()
  const { mutate: loadBucketMutate } = useLoadAwsBucketName()
  const { mutate: addTimeSheetMutate } = useAddTimeSheetData()
  const { mutate: mutateS3Creds } = useLoadS3Creds()
  const { mutate: mutateLoadAssetAuthToken } = useLoadAssetAuthToken()
  const { mutate: lastSeenMutate } = useLastSeenUpdate()

  useEffect(() => {
    loadUserDetails()
  }, [])

  const loadBucketName = useCallback(() => {
    loadBucketMutate(
      {},
      {
        onSuccess: async res => {
          if (res.data.status === 200) {
            const { bucket_name } = res.data.response[0]
            if (!!bucket_name) {
              dispatch({
                type: loginConst.SET_BUCKET_NAME,
                bucket_name,
              })
            }
          } else {
            console.log('Error Occured!', res)
          }
        },
      }
    )
  })

  const loadUserDetails = useCallback(data => {
    const payload = getLocalAuthDetails()
    mutate(
      { ...payload },
      {
        onSuccess: async res => {
          if (res?.data?.status === 200) {
            const userData = res.data.response.data
            localStorage.setItem(
              'ORGANIZATION_ONHOLD',
              userData && userData.organization_flag_dashboard_onhold
            )
            if (userData.organization_flag_timeline_filter === 1) {
              localStorage.setItem(
                'timelineFilter',
                JSON.stringify({
                  value: TIMELINE_MESSAGE_TYPE.ALL,
                  label: locale['All'],
                })
              )
            } else {
              localStorage.setItem(
                'timelineFilter',
                JSON.stringify({
                  value: TIMELINE_MESSAGE_TYPE.DEFAULT,
                  label: 'Default',
                })
              )
            }
            if (
              !!userData &&
              (userData.workforce_flag_enable_web_access === 0 ||
                userData.organization_enterprise_features_enabled === 0)
            ) {
              dispatch({
                type: loginConst.LOGOUT,
              })
              history.push('/login?access=false')
            }
            setUser(userData)
            if (!!userData.organization_inline_data) {
              userData.organization_file_templates = JSON.parse(
                userData.organization_inline_data
              ).organization_file_templates
              let parseData = JSON.parse(userData.organization_inline_data)

							localStorage.setItem(
								'color',
								!!parseData.color_1 ? parseData.color_1 : '#4daf89'
							)
							localStorage.setItem(
								'font',
								!!parseData.font_name ? parseData.font_name : 'Roboto'
							)
							localStorage.setItem(
								'imgWidth',
								!!parseData.logo_width_in_px
									? `${parseData.logo_width_in_px}px`
									: '60px'
							)
						} else {
							localStorage.setItem('color', '#4daf89')
							localStorage.setItem('font', 'Roboto')
							localStorage.setItem('imgWidth', '60px')
						}
            if (
              !userData.asset_token_auth &&
              !userData.asset_encryption_token_id
            ) {
              mutateLoadAssetAuthToken(
                { userData },
                {
                  onSuccess: data => {
                    setLocalAuthDetails(data)
                    let {
                      asset_token_auth = '',
                      asset_encryption_token_id = '',
                    } = data
                    lastSeenMutate(
                      {
                        organization_id: data.organization_id,
                        asset_id: data.asset_id,
                        location_datetime: getISTtoUTC(),
                        asset_token_auth:
                          asset_token_auth || asset_encryption_token_id,
                      },
                      {
                        onSuccess: res => {
                          console.log(res)
                        },
                      }
                    )
                    dispatch({
                      type: loginConst.SAVE_USER_AUTH,
                      payload: data,
                    })
                  },
                }
              )
            } else {
              setLocalAuthDetails(userData)
              let { asset_token_auth = '', asset_encryption_token_id = '' } =
                userData
              lastSeenMutate(
                {
                  organization_id: userData.organization_id,
                  asset_id: userData.asset_id,
                  asset_token_auth:
                    asset_token_auth || asset_encryption_token_id,
                  location_datetime: getISTtoUTC(),
                },
                {
                  onSuccess: res => {
                    console.log(res)
                  },
                }
              )
              dispatch({
                type: loginConst.SAVE_USER_AUTH,
                payload: userData,
              })
            }
            loadBucketName()
            mutateS3Creds({})
          } else if (res.isAxiosError) {
            console.log(history.location.pathname.split('/')[2])
            let len = history.location.pathname.lastIndexOf('/')
            console.log('Something went wrong please login again!', len)
            window.location.href = `/weblink${history.location.pathname.substring(
              len,
              history.location.pathname.length
            )}?redirect=${
              'originform-' + history.location.pathname.split('/')[2]
            }`
          } else {
            console.log('Something went wrong please login again!')
          }
        },
        onError: async err => {
          console.log(history.location.pathname.split('/')[2])
          let len = history.location.pathname.lastIndexOf('/')
          console.log('Something went wrong please login again!', err, len)
          window.location.href = `/weblink${history.location.pathname.substring(
            len,
            history.location.pathname.length
          )}?redirect=${
            'originform-' + history.location.pathname.split('/')[2]
          }`
        },
      }
    )
  }, [])
  return (
    <Container
      h="100vh"
      minW={'100vw'}
      m={0}
      p={0}
      display={'flex'}
      flexDirection={'column'}
    >
      <Flex
        borderBottomWidth={'2px'}
        width={'100%'}
        height="75px"
        bg={localStorage.getItem('color')}
        alignItems="center"
      >
        {/* <Flex flexDir="column" justifyContent="center" alignItems="flex-start">
          <HStack>
            <Box>
              <HStack spacing={1}>
                <Image src={imageData} ml="15px" maxW="100px" maxH={'100px'} />
              </HStack>
            </Box>
          </HStack>
        </Flex> */}
        <Spacer />
        <Text
          fontSize={'large'}
          fontWeight={'bold'}
          mr={'10px'}
          color={'white'}
        >
          {formName}
        </Text>
      </Flex>
      <SharedContextProvider>
        <Flex
          bg={'white'}
          h={'100vh'}
          d={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          overflow={'auto'}
        >
          {children}
        </Flex>
      </SharedContextProvider>
      {/* <Text fontSize="sm" color="black.300" m={4}>
        <a href="https://greneos.com/" target="_blank" rel="noreferrer">
          Grene Robotics Pvt. Ltd. - 2021
        </a>{' '}
        | All Rights Reserved
      </Text> */}
    </Container>
  )
}

export default WebLinkLayout
