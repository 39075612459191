/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Flex,
	Heading,
	Image,
	Text,
	Container,
	HStack,
	Spacer,
} from '@chakra-ui/react'
import React, { useEffect, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import GreneLogo from '../assets/images/grene-logo-text.png'
import VILLogo from '../assets/images/vi_logo_large_white.png'
import { useLoadS3Creds } from '../hooks/shared.hooks'
import { localizationConst, loginConst } from '../utils/action.constant'
import {
	getLocalAuthDetails,
	setLocalAuthDetails,
	getISTtoUTC,
} from '../utils/common.util'
import { useLastSeenUpdate } from '../hooks/login.hooks'
import { useAuthContext } from '../context/auth.context'
import { SharedContextProvider } from '../context/shared.context'
import {
	useLoadAssetInlineCollection,
	useLoadAwsBucketName,
	useLoadAssetAuthToken,
	useAddTimeSheetData,
} from '../hooks/asset.hooks'
import { useLanguageContext } from '../context/locale.context'
import { IS_WEBLOGIN_LINK } from '../utils/constant'
import { useGetTranslations } from '../hooks/dashboard.hooks'

const DashboardLayout = ({ children, orgId }) => {
	const imageData = orgId === 868 ? VILLogo : GreneLogo
	const { dispatch } = useAuthContext()
	const {
		state: { locale },
		dispatch: languageDispatch,
	} = useLanguageContext()
	const history = useHistory()
	const [user, setUser] = useState(null)
	const [params, setParams] = useState(null)
	const [authData, setAuthData] = useState(null)

	const { mutate, isLoading } = useLoadAssetInlineCollection()
	const { mutate: loadBucketMutate } = useLoadAwsBucketName()
	const { mutate: addTimeSheetMutate } = useAddTimeSheetData()
	const { mutate: mutateS3Creds } = useLoadS3Creds()
	const { mutate: mutateLoadAssetAuthToken } = useLoadAssetAuthToken()
	const { mutate: lastSeenMutate } = useLastSeenUpdate()
	const { mutate: loadLanguageTexts } = useGetTranslations()

	useEffect(() => {
		localStorage.setItem('x-grene-header-disable', true)
		localStorage.setItem('x-grene-form-view', true)
		localStorage.setItem('x-form-pass', true)
		localStorage.setItem(IS_WEBLOGIN_LINK, 'true')
		localStorage.setItem('IS_MOBILE_VIEW', 'true')
		let len = history.location.pathname.length
		let path = history.location.pathname.lastIndexOf('/') + 1
		let urlParams = history.location.pathname.substring(path, len)
		let url = atob(urlParams)
		let parsed = JSON.parse(url)
		setAuthData({
			...parsed,
		})
		setParams(urlParams)
	}, [])

	useEffect(() => {
		!!authData &&
			Object.keys(locale).length === 0 &&
			setSelectedLanguage({ label: 'English', value: 1 }, authData)
	}, [locale, authData])

	const setSelectedLanguage = (selectedLanguage, authData) => {
		loadLanguageTexts(
			{
				...authData,
				auth_asset_id: authData?.asset_id,
				language_id: selectedLanguage.value,
				limit_value: 500,
				start_from: 0,
			},
			{
				onSuccess: data => {
					if (!!data)
						languageDispatch({
							type: localizationConst.SET_SELECTED_LANGUAGE,
							selectedLanguage,
							data,
						})
				},
				onError: err => {},
			}
		)
	}

	useEffect(() => {
		if (!!authData) loadUserDetails(authData)
	}, [authData])

	const loadBucketName = useCallback(() => {
		loadBucketMutate(
			{},
			{
				onSuccess: async res => {
					if (res.data.status === 200) {
						const { bucket_name } = res.data.response[0]
						if (!!bucket_name) {
							dispatch({
								type: loginConst.SET_BUCKET_NAME,
								bucket_name,
							})
						}
					} else {
						console.log('Error Occured!', res)
					}
				},
			}
		)
	})

	const loadUserDetails = useCallback(data => {
		const payload = data
		mutate(
			{ ...payload },
			{
				onSuccess: async res => {
					if (res?.data?.status === 200) {
						const userData = res.data.response.data
						setUser(userData)
						if (!!userData.organization_inline_data) {
							userData.organization_file_templates = JSON.parse(
								userData.organization_inline_data
							).organization_file_templates
							let parseData = JSON.parse(userData.organization_inline_data)

							localStorage.setItem(
								'color',
								!!parseData.color_1 ? parseData.color_1 : '#4daf89'
							)
							localStorage.setItem(
								'font',
								!!parseData.font_name ? parseData.font_name : 'Roboto'
							)
							localStorage.setItem(
								'imgWidth',
								!!parseData.logo_width_in_px
									? `${parseData.logo_width_in_px}px`
									: '60px'
							)
						} else {
							localStorage.setItem('color', '#4daf89')
							localStorage.setItem('font', 'Roboto')
							localStorage.setItem('imgWidth', '60px')
						}
						if (
							!userData.asset_token_auth &&
							!userData.asset_encryption_token_id
						) {
							mutateLoadAssetAuthToken(
								{ userData },
								{
									onSuccess: data => {
										setLocalAuthDetails(data)
										let {
											asset_token_auth = '',
											asset_encryption_token_id = '',
										} = data
										lastSeenMutate(
											{
												organization_id: data.organization_id,
												asset_id: data.asset_id,
												location_datetime: getISTtoUTC(),
												asset_token_auth:
													asset_token_auth || asset_encryption_token_id,
											},
											{
												onSuccess: res => {
													console.log(res)
												},
											}
										)
										dispatch({
											type: loginConst.SAVE_USER_AUTH,
											payload: data,
										})
									},
								}
							)
						} else {
							setLocalAuthDetails(userData)
							dispatch({
								type: loginConst.SAVE_USER_AUTH,
								payload: userData,
							})
						}
						loadBucketName()
						mutateS3Creds({})
					} else {
						console.log('Something went wrong please login again!')
					}
				},
				onError: async err => {
					console.log('Something went wrong please login again!', err)
				},
			}
		)
	}, [])

	return (
		// <Container
		//   h="100vh"
		//   minW={'100vw'}
		//   m={0}
		//   p={0}
		//   display={'flex'}
		//   flexDirection={'column'}
		// >
		//   <SharedContextProvider>{children}</SharedContextProvider>
		// </Container>
		<Container
			h='100vh'
			minW={'100vw'}
			m={0}
			p={0}
			display={'flex'}
			flexDirection={'column'}
		>
			<Flex
				borderBottomWidth={'2px'}
				width={'100%'}
				// height="75px"
				bg='#4daf89'
				alignItems='center'
			>
				<Spacer />
				{/* <Text
        fontSize={'large'}
        fontWeight={'bold'}
        mr={'10px'}
        color={'white'}
      >
        Dashboard Data
      </Text> */}
			</Flex>
			<SharedContextProvider>
				<Flex
					bg={'white'}
					h={'100vh'}
					d={'flex'}
					justifyContent={'center'}
					alignItems={'center'}
					overflow={'auto'}
				>
					{children}
				</Flex>
			</SharedContextProvider>
		</Container>
	)
}

export default DashboardLayout
