/* eslint-disable react-hooks/exhaustive-deps */
import * as faceapi from 'face-api.js'
import {
	Container,
	Flex,
	HStack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	Skeleton,
	VStack,
	Text,
	Button,
	Box,
	useDisclosure,
	useToast,
} from '@chakra-ui/react'
import * as moment from 'moment'
import React, { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import Navbar from '../components/navbar'
import Sidebar from '../components/sidebar'
import { useAuthContext } from '../context/auth.context'
import { SharedContextProvider } from '../context/shared.context'
import {
	useLoadAssetInlineCollection,
	useLoadAwsBucketName,
	useLoadAssetAuthToken,
	useAddTimeSheetData,
} from '../hooks/asset.hooks'
import { useLoadS3Creds } from '../hooks/shared.hooks'
import { loginConst } from '../utils/action.constant'
import {
	getLocalAuthDetails,
	setLocalAuthDetails,
	getISTtoUTC,
} from '../utils/common.util'
import { useLogOut, useLastSeenUpdate } from '../hooks/login.hooks'
import notificationTone from '../assets/ringtones/notification.mp3'
import { TIMELINE_MESSAGE_TYPE } from '../components/timeline/util'
import { useLanguageContext } from '../context/locale.context'
import { sectionDetailedTrack } from '../containers/repository/repo.utils'
import { GA_CATEGORY_DIALOG_BOX } from './../containers/repository/repo.utils'
import { useDeepStreamContext } from '../context/deepstream.context'
import AWS from 'aws-sdk'
import { Auth } from 'aws-amplify'
import useSound from 'use-sound'
import CountdownTimer from '../components/countdown-timer'

const timer = 20

const PrivateLayout = ({ children }) => {
	const [user, setUser] = useState(null)
	const [away, setIsAway] = useState(null)
	const [playSound, { stop }] = useSound(notificationTone, {
		loop: true,
		autoLoad: true,
	})

	const history = useHistory()
	const toast = useToast({
		duration: 1000,
	})
	const { dispatch } = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const {
		state: { ds },
	} = useDeepStreamContext()
	const { mutate, isLoading } = useLoadAssetInlineCollection()
	const { mutate: loadBucketMutate } = useLoadAwsBucketName()
	const { mutate: addTimeSheetMutate } = useAddTimeSheetData()
	const { mutate: mutateS3Creds } = useLoadS3Creds()
	const { mutate: mutateLoadAssetAuthToken } = useLoadAssetAuthToken()
	const { mutate: mutatelogOut } = useLogOut()
	const { mutate: lastSeenMutate } = useLastSeenUpdate()
	const {
		isOpen: isTimerOpen,
		onClose: onTimerClose,
		onOpen: onTimerOpen,
	} = useDisclosure()
	const [showSessionTimer, setShowSessionTimer] = useState(false)
	const [sessionTime, setSessionTime] = useState(1800)
	const [stream, setStream] = useState(null)

	const loadBucketName = useCallback(() => {
		loadBucketMutate(
			{},
			{
				onSuccess: async res => {
					if (res.data.status === 200) {
						const { bucket_name } = res.data.response[0]
						if (!!bucket_name) {
							dispatch({
								type: loginConst.SET_BUCKET_NAME,
								bucket_name,
							})
						}
					} else {
						console.log('Error Occured!', res)
					}
				},
			}
		)
	})

	const stopVideoFeed = () => {
		if (!!stream) {
			stream.getVideoTracks().forEach(track => {
				track.stop()
			})
		}
	}

	const setAwayStatus = async status => {
		try {
			console.log('PL: SETAWAY STATUS ', status)
		} catch (err) {
			console.log('PL: SETAWAY STATUS ', err)
		}
	}

	const pointerInHandler = () => {
		setShowSessionTimer(false)
		setSessionTime(1800)
	}

	const pointerOutHandler = () => {
		setShowSessionTimer(true)
	}

	const triggerLogoutPopup = () => {
		setShowSessionTimer(false)
		playSound()
		onTimerOpen()
	}

	const closeTimerModal = () => {
		setShowSessionTimer(false)
		stop()
		onTimerClose()
		initiateFaceDetector()
	}

	const checkUserPresence = async timeoutId => {
		const video = document.getElementById('userCameraView')
		if (!video) {
			return
		}
		const detections = await faceapi
			.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
			.withFaceLandmarks()
		if (detections.length > 0) {
			setAwayStatus(false)
			setIsAway(false)
			pointerInHandler()
			clearTimeout(timeoutId)
			timeoutId = 0
		} else if (!timeoutId) {
			timeoutId = setTimeout(() => {
				setAwayStatus(true)
				setIsAway(true)
				pointerOutHandler()
				clearTimeout(timeoutId)
			}, 10000)
		}
		setTimeout(() => {
			checkUserPresence(timeoutId)
		}, 300000) // 5 Min
	}

	const onVideoPlayListner = () => {
		checkUserPresence(0)
		const video = document.getElementById('userCameraView')
		if (!!video) {
			setInterval(async () => {
				const detections = await faceapi
					.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
					.withFaceLandmarks()
				if (!!video && detections.length > 0) {
					addTimeSheetMutate(
						{
							start_datetime: moment().utc().format(),
							end_datetime: moment().utc().format(),
							work_hours_duration: 10,
							swipe_type_id: 1,
							flag: 0,
						},
						{
							onSuccess: async data => {
								// console.log('timesheet update', data);
							},
						}
					)
				}
			}, 600000) /// updating the timesheet on every 10mins
		}
	}

	const startFaceDetectorVideo = () => {
		const video = document.getElementById('userCameraView')
		if (!!video) {
			let sbrowser = navigator.userAgent
			if (sbrowser.indexOf('Firefox') > -1) {
				toast({
					title: 'Failed!',
					description: `Chrome browser is compatible for all features in GreneOS Virtual
          Office, please download one of the free browsers listed below
          \n Chrome(version 84 or higher)
          \n
          MS Edge(version 84 or higher)
          \n
          Safari(version 12 or higher)`,
					status: 'error',
				})
			} else {
				navigator.mediaDevices
					.getUserMedia({
						video: {
							frameRate: { ideal: 10, max: 15 },
							height: { ideal: 360 },
						},
						audio: false,
					})
					.then(stream => {
						video.srcObject = stream
						setStream(stream)
					})
					.catch(err => console.error('error on user cam stream output', err))
			}
		}
	}

	const initiateFaceDetector = useCallback(() => {
		Promise.all([
			faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
			faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
		])
			.then(() => startFaceDetectorVideo())
			.catch(error => {
				console.log('error occurred on promise resolve', error)
			})
	}, [])

	const loadUserDetails = useCallback(data => {
		const payload = getLocalAuthDetails()
		mutate(
			{ ...payload },
			{
				onSuccess: async res => {
					if (res.data.status === 200) {
						const userData = res.data.response.data
						localStorage.setItem(
							'ORGANIZATION_ONHOLD',
							userData && userData.organization_flag_dashboard_onhold
						)
						if (userData.organization_flag_timeline_filter === 1) {
							localStorage.setItem(
								'timelineFilter',
								JSON.stringify({
									value: TIMELINE_MESSAGE_TYPE.ALL,
									label: locale['All'],
								})
							)
						} else {
							localStorage.setItem(
								'timelineFilter',
								JSON.stringify({
									value: TIMELINE_MESSAGE_TYPE.DEFAULT,
									label: 'Default',
								})
							)
						}
						if (
							!!userData &&
							(userData.workforce_flag_enable_web_access === 0 ||
								userData.organization_enterprise_features_enabled === 0)
						) {
							dispatch({
								type: loginConst.LOGOUT,
							})
							history.push('/login?access=false')
						}
						setUser(userData)
						if (!!userData.organization_inline_data) {
							userData.organization_file_templates = JSON.parse(
								userData.organization_inline_data
							).organization_file_templates
							let parseData = JSON.parse(userData.organization_inline_data)

							localStorage.setItem(
								'color',
								!!parseData.color_1 ? parseData.color_1 : '#4daf89'
							)
							localStorage.setItem(
								'font',
								!!parseData.font_name ? parseData.font_name : 'Roboto'
							)
							localStorage.setItem(
								'imgWidth',
								!!parseData.logo_width_in_px
									? `${parseData.logo_width_in_px}px`
									: '60px'
							)
						} else {
							localStorage.setItem('color', '#4daf89')
							localStorage.setItem('font', 'Roboto')
							localStorage.setItem('imgWidth', '60px')
						}
						if (
							!userData.asset_token_auth &&
							!userData.asset_encryption_token_id
						) {
							mutateLoadAssetAuthToken(
								{ userData },
								{
									onSuccess: data => {
										setLocalAuthDetails(data)
										let {
											asset_token_auth = '',
											asset_encryption_token_id = '',
										} = data
										lastSeenMutate(
											{
												organization_id: data.organization_id,
												asset_id: data.asset_id,
												location_datetime: getISTtoUTC(),
												asset_token_auth:
													asset_token_auth || asset_encryption_token_id,
											},
											{
												onSuccess: res => {
													console.log(res)
												},
											}
										)
										dispatch({
											type: loginConst.SAVE_USER_AUTH,
											payload: data,
										})
									},
								}
							)
						} else {
							setLocalAuthDetails(userData)
							let { asset_token_auth = '', asset_encryption_token_id = '' } =
								userData
							lastSeenMutate(
								{
									organization_id: userData.organization_id,
									asset_id: userData.asset_id,
									asset_token_auth:
										asset_token_auth || asset_encryption_token_id,
									location_datetime: getISTtoUTC(),
								},
								{
									onSuccess: res => {
										console.log(res)
									},
								}
							)
							dispatch({
								type: loginConst.SAVE_USER_AUTH,
								payload: userData,
							})
						}
						initiateFaceDetector()
						loadBucketName()
						mutateS3Creds({})
					} else {
						console.log('Something went wrong please login again!')
					}
				},
				onError: async err => {
					console.log('Something went wrong please login again!', err)
					// dispatch({
					//   type: loginConst.LOGOUT,
					// });
					// history.push('/login');
				},
			}
		)
	})

	const handleLogout = () => {
		if (!!process.env.REACT_APP_AD_USER_POOL_ID) {
			AWS.config.credentials.clearCachedId()
			dispatch({
				type: loginConst.LOGOUT,
			})
			history.push('/login')
			setTimeout(() => {
				window.location.reload()
			}, 1000)
		} else {
			mutatelogOut(
				{},
				{
					onSuccess: () => {
						Auth.signOut()
						dispatch({
							type: loginConst.LOGOUT,
						})
						stopVideoFeed()
						history.push('/login')
						window.location.reload()
					},
				}
			)
		}
	}

	useEffect(() => {
		loadUserDetails()
	}, [])

	return (
		<Container
			h='100vh'
			minW={'100vw'}
			m={0}
			p={0}
			display={'Flex'}
			onPointerEnter={() => pointerInHandler()}
			onPointerLeave={() => pointerOutHandler()}
		>
			<SharedContextProvider>
				<Navbar
					handleLogout={handleLogout}
					onVideoPlayListner={onVideoPlayListner}
					sessionTime={sessionTime}
					triggerLogoutPopup={triggerLogoutPopup}
					showSessionTimer={showSessionTimer}
				/>
				<HStack
					spacing={1}
					marginTop='60px'
					width='100vw'
					h='calc(100vh - 60px)'
					alignItems='flex-start'
				>
					<Sidebar />
					<Flex flex={1} justifyContent='flex-start'>
						<Container maxW='100%' width='100%' my={4} mx={2} px={1}>
							{children}
						</Container>
					</Flex>
				</HStack>
				<Modal isOpen={isTimerOpen} onClose={closeTimerModal}>
					<ModalOverlay />
					<ModalContent>
						<ModalBody>
							<Flex flexDirection='column' align='center'>
								<CountdownTimer
									sessionTime={timer}
									triggerLogoutPopup={handleLogout}
								/>
								<Text mt={3} mb={3}>
									{locale['Are you still available?']}
								</Text>
								<Button
									variant='solid'
									colorScheme={localStorage.getItem('color')}
									bg={localStorage.getItem('color')}
									onClick={() => {
										sectionDetailedTrack({
											category: GA_CATEGORY_DIALOG_BOX,
											action: 'Close Timer Modal',
										})
										closeTimerModal()
									}}
								>
									{locale['Continue']}
								</Button>
							</Flex>
						</ModalBody>
					</ModalContent>
				</Modal>
			</SharedContextProvider>
		</Container>
	)
}

export default PrivateLayout
