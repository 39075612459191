import {
	Box,
	Flex,
	Heading,
	Image,
	Text,
	Button,
	useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import greneLogo from '../assets/images/grene-logo-text.png'
import loginBg from '../assets/images/login-bg.png'
import TermsAndConditions from '../components/termsAndConditions'
const PublicLayout = ({ children, urlParams }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	return (
		<>
			<TermsAndConditions isOpen={isOpen} onClose={onClose} />
			<Flex
				// w="110vw"
				h='100vh'
				backgroundRepeat='no-repeat'
				backgroundPosition='bottom left'
				backgroundSize='100%'
				backgroundImage={`url(${loginBg})`}
			>
				{!!urlParams ? null : (
					<Flex w='49%' minW='400px' h='full' direction='column'>
						<Box minW='250px' h='100%' p={6}>
							<Image src={greneLogo} ml='-10px' maxW='200px' />
							<Heading fontWeight='600' size='xl'>
								Welcome <br /> to your Work-Web-Desk
							</Heading>
						</Box>
						<Flex>
							<Text fontSize='sm' m={4}>
								<a href='https://greneos.com/' target='_blank' rel='noreferrer'>
									Grene Robotics Pvt. Ltd. - 2021
								</a>{' '}
								| All Rights Reserved
							</Text>
							<Text mt='15px'>
								<a
									href='#'
									onClick={onOpen}
									style={{
										fontSize: '0.875rem',
										fontWeight: '500',
										lineHeight: '1.375rem',
										textDecoration: 'none', // Remove underline
										color: 'black.300', // Change color to match button style
										cursor: 'pointer', // Change cursor to pointer on hover
									}}
								>
									Terms of Service and Privacy Policy
								</a>
							</Text>
						</Flex>
					</Flex>
				)}

				<Flex
					flex={1}
					h='full'
					alignItems='center'
					justifyContent='center'
					flexDir='column'
				>
					{children}
				</Flex>
			</Flex>
		</>
	)
}

export default PublicLayout
